@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Buda&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Lobster&family=Lora:ital,wght@0,400..700;1,400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=Pacifico&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Ultra&display=swap');


body {
  background-color: #000; /* Black background */
  color: #ffffff; /* White text color for contrast */
}

html, body {
  overscroll-behavior: none; /* Disables scroll bounce/elastic scroll */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

body {
  font-family: 'Ubuntu', sans-serif;
}

.font-lora {
  font-family: 'Lora', serif;
}

.font-anton {
  font-family: 'Anton', sans-serif;
}

.font-oswald {
  font-family: 'Oswald', sans-serif;
}

.font-play {
  font-family: 'play', serif;
}